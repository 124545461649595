import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import * as Ant from "antd";

import { CustomInput, CustomPasswordInput } from "./Login/CustomComponents";
import ActionCreator from "../ActionCreator";
import Widget, { Color, FontSize, Container } from "./Widget";
import { mobileRule } from "../Utils/RegexRules";

const messageDefault = {
  getOTPVerify: "",
  verifyIdentity: "重新設定密碼，須先驗證身份",
  savePassword: "重新設定密碼加強帳戶安全性，並請符合以下規則",
};
const errorMessageDefault = {
  getOTPVerify: "",
  verifyIdentity: "",
  verifyOTP: "",
  savePassword: "",
};

const passwordRules = [
  {
    label: "至少英文大小寫各 1 個",
    rule: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+-{}]+$/,
  },
  { label: "包含數字", rule: /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()_+-{}]+$/ },
  {
    label: "包含特殊符號 1 個",
    rule: /^(?=.*[@#$!%*?&])[a-zA-Z0-9!@#$%^&*()_+-{}]+$/,
  },
  {
    label: "密碼長度 12 字元以上",
    rule: /^[a-zA-Z0-9!@#$%^&*()_+-{}]{12,}$/,
  },
];

const formGetOTPDefault = { account: "", password: "" };
const formVerifyOTPDefault = { verifyCode: "" };
const formVerifyIdentityDefault = { account: "", phone: "", Id: "" };
const formSavePasswordDefault = { newPassword: "", passwordConfirm: "" };

const LoginForm = props => {
  const { appActions } = props;
  const resendSeconds = 60;

  // case "getOTPVerify":
  //       return renderFormGetOTPVerify();
  //     case "verifyOTP":
  //       return renderFormVerifyOTP();
  //     case "verifyIdentity":
  //       return renderFormVerifyIdentity();
  //     case "savePassword":
  const [page, setPage] = useState("getOTPVerify");
  const [formGetOTP, setFormGetOTP] = useState({ ...formGetOTPDefault });
  const [formVerifyOTP, setFormVerifyOTP] = useState({
    ...formVerifyOTPDefault,
  });
  const [formVerifyIdentity, setFormVerifyIdentity] = useState({
    ...formVerifyIdentityDefault,
  });
  const [formSavePassword, setFormSavePassword] = useState({
    ...formSavePasswordDefault,
  });
  const [message, setMessage] = useState({ ...messageDefault });
  const [errorMessage, setErrorMessage] = useState({ ...errorMessageDefault });
  const [loading, setLoading] = useState({
    getOTPVerify: false,
    verifyOTP: false,
    resendOTP: false,
    savePassword: false,
    verifyIdentity: false,
  });
  const [countDownResend, setCountDownResend] = useState(resendSeconds);

  const user = useSelector(state => state.user);
  const userMessage = user.message;

  const OTPVerifyFailedCount = useRef(0);
  const resendOTPSetTimeoutRef = useRef();

  // 按下 "取得 OTP 驗證碼" / "再次發送" 按鈕觸發
  const GetOTPVerifyCode = () => {
    if (formGetOTP.account === "" || formGetOTP.password === "") {
      return setErrorMessage(state => ({
        ...state,
        getOTPVerify: "帳號/密碼不得為空白",
      }));
    }
    setFormVerifyIdentity({ ...formVerifyIdentityDefault });
    setLoading(state => ({ ...state, getOTPVerify: true, resendOTP: true }));
    appActions
      .getOTPVerify(formGetOTP.account.trim(), formGetOTP.password)
      .then(res => {
        if (res.code === "200" || res.code === "106") {
          setErrorMessage(state => ({
            ...state,
            getOTPVerify: "",
          }));
          setPage("verifyOTP");
          startCountDownResend();
        } else if (res.code === "") {
          setPage("savePassword");
          setMessage(state => ({ ...state, savePassword: res.message }));
        } else {
          setErrorMessage(state => ({ ...state, getOTPVerify: res.message }));
        }
      })
      .finally(() =>
        setLoading(state => ({
          ...state,
          getOTPVerify: false,
          resendOTP: false,
        }))
      );
  };

  // 按下 "驗證 OTP" 按鈕觸發
  const handleVerifyOTP = () => {
    setLoading(state => ({ ...state, verifyOTP: true }));
    appActions
      .checkOTPVerify(
        formGetOTP.account || formVerifyIdentity.account,
        formGetOTP.password || formVerifyIdentity.phone,
        formVerifyOTP.verifyCode
      )
      .then(res => {
        if (res.code === "200") {
          setErrorMessage(state => ({
            ...state,
            verifyOTP: "",
          }));
        } else if (res.code === "108" || res.code === "109") {
          setFormVerifyIdentity({ Id: res.data.UserId });
          if (res.code === "109") {
            setErrorMessage(state => ({
              ...state,
              savePassword: "已超過90天未更新，須重新設定且不可與前三次相同",
            }));
          }
          setPage("savePassword");
        } else {
          OTPVerifyFailedCount.current += 1;
          if (OTPVerifyFailedCount.current === 3) {
            // 應該不需要 logout 還沒有 token
            // appActions.logout(formGetOTP.account);
            OTPVerifyFailedCount.current = 0;
            stopCountDownResend();
            setPage("getOTPVerify");
            setErrorMessage(state => ({
              ...state,
              getOTPVerify: "OTP錯誤達 3 次，需等到30分鐘後才能使用",
            }));

            return;
          }
          setErrorMessage(state => ({ ...state, verifyOTP: res.message }));
          setFormVerifyOTP({ ...formVerifyOTPDefault });
        }
      })
      .finally(() => setLoading(state => ({ ...state, verifyOTP: false })));
  };

  // 在 "OTP 驗證碼" input 輸入後觸發
  const handleChangeOTPCode = e => {
    const val = e.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(val)) return;
    setFormVerifyOTP(state => ({ ...state, verifyCode: val }));
  };

  const handleVerifyIdentity = () => {
    setLoading(state => ({ ...state, verifyIdentity: true }));
    appActions
      .verifyUser(formVerifyIdentity.account, formVerifyIdentity.phone)
      .then(res => {
        if (res.code === "200") {
          setErrorMessage(state => ({
            ...state,
            verifyIdentity: "",
          }));
          setPage("verifyOTP");
        } else {
          setErrorMessage(state => ({ ...state, verifyIdentity: res.message }));
          setFormVerifyIdentity({ ...formVerifyIdentityDefault });
        }
      })
      .finally(() =>
        setLoading(state => ({ ...state, verifyIdentity: false }))
      );
  };

  const handleClickForgotPw = () => {
    setFormGetOTP({ ...formGetOTPDefault });
    setErrorMessage(state => ({
      ...state,
      getOTPVerify: "",
    }));
    setFormVerifyIdentity({ ...formVerifyIdentityDefault });
    setPage("verifyIdentity");
  };

  const handleSavePassword = () => {
    const { newPassword, passwordConfirm } = formSavePassword;
    if (newPassword !== passwordConfirm) {
      setErrorMessage(state => ({
        ...state,
        savePassword: "兩組密碼不一致，請重新設定",
      }));
      setFormSavePassword(state => ({
        ...state,
        newPassword: "",
        passwordConfirm: "",
      }));
      return;
    }

    setLoading(state => ({ ...state, savePassword: true }));
    appActions.changePassword(formVerifyIdentity.Id, newPassword).then(res => {
      setLoading(state => ({ ...state, savePassword: false }));
      if (res.code === "200") {
        setPage("getOTPVerify");
        setFormSavePassword({ ...formSavePasswordDefault });
        setMessage(state => ({
          ...state,
          getOTPVerify: "您的密碼已經設定成功，請重新登入",
        }));
      } else {
        setMessage(state => ({
          ...state,
          savePassword: res.message,
        }));
      }
    });
  };

  const handleResend = () => {
    GetOTPVerifyCode();
    setErrorMessage({ ...errorMessageDefault });
    setFormVerifyOTP({ ...formVerifyOTPDefault });
  };

  // 開始倒數
  const startCountDownResend = () => {
    setCountDownResend(resendSeconds);
    resendOTPSetTimeoutRef.current = setInterval(() => {
      setCountDownResend(prevState => prevState - 1);
    }, 1000);
  };

  // 停止倒數
  const stopCountDownResend = () => {
    if (resendOTPSetTimeoutRef.current) {
      clearInterval(resendOTPSetTimeoutRef.current);
    }
  };

  const renderFormHeader = () => {
    return (
      <Logo showMessage={!!userMessage}>
        <img src="/images/Survey_Logo_Color_v2.svg" alt="" />
      </Logo>
    );
  };

  const renderFormFooter = () => {
    return (
      <AlreadyHaveAcWrapper>
        <span>已有帳號密碼？ 請點選</span>
        <Ant.Button
          type="text"
          onClick={() => {
            setPage("getOTPVerify");
            setErrorMessage({ ...errorMessageDefault });
          }}
        >
          登入頁
        </Ant.Button>
      </AlreadyHaveAcWrapper>
    );
  };

  const renderFormGetOTPVerify = () => {
    const { account, password } = formGetOTP;
    const errMsg = errorMessage.getOTPVerify;
    return (
      <>
        {renderFormHeader()}
        <Message isError={!!errMsg}>
          {!!errMsg ? errMsg : message.getOTPVerify}
        </Message>
        <CustomInput
          name="account"
          label="帳號"
          placeholder="LDAP帳號"
          value={account}
          handleChange={e => {
            const val = e.target.value;
            setFormGetOTP(state => ({ ...state, account: val }));
          }}
        />
        <CustomPasswordInput
          name="password"
          label="密碼"
          placeholder="請輸入密碼"
          value={password}
          handleChange={e => {
            const val = e.target.value;
            setFormGetOTP(state => ({ ...state, password: val }));
          }}
        />
        <ForgotPasswordWrapper>
          <Ant.Button type="text" onClick={handleClickForgotPw}>
            忘記密碼
          </Ant.Button>
        </ForgotPasswordWrapper>
        <div style={{ marginBottom: "52px" }}>
          <Ant.Button
            className="submitButton get-otp-button"
            type="primary"
            loading={loading.getOTPVerify}
            onClick={GetOTPVerifyCode}
          >
            <p>取得 OTP 驗證碼</p>
          </Ant.Button>
        </div>
      </>
    );
  };

  const renderFormVerifyOTP = () => {
    const errMsg = errorMessage.verifyOTP;
    return (
      <>
        {renderFormHeader()}
        <Message isError={!!errMsg}>{!!errMsg ? errMsg : ""}</Message>
        <CustomInput
          name="verifyCode"
          label="OTP 驗證碼"
          placeholder="請輸入6位數字"
          value={formVerifyOTP.verifyCode}
          handleChange={handleChangeOTPCode}
        />
        <div style={{ marginBottom: "30px" }}>
          <Ant.Button
            className="verifyButton resend-otp-button"
            type="primary"
            disabled={countDownResend > 0}
            loading={loading.resendOTP}
            onClick={handleResend}
          >
            {countDownResend > 0 ? (
              <p>再次發送({countDownResend}秒)</p>
            ) : (
              <p>再次發送</p>
            )}
          </Ant.Button>
          <Ant.Button
            className="submitButton verify-otp-button"
            type="primary"
            loading={loading.verifyOTP}
            onClick={handleVerifyOTP}
          >
            <p>驗證 OTP</p>
          </Ant.Button>
        </div>
      </>
    );
  };

  const renderFormVerifyIdentity = () => {
    const { account, phone } = formVerifyIdentity;
    const errMsg = errorMessage.verifyIdentity;
    return (
      <>
        {renderFormHeader()}
        <Message isError={!!errMsg}>
          {!!errMsg ? errMsg : message.verifyIdentity}
        </Message>
        <CustomInput
          name="account"
          label="帳號"
          placeholder="LDAP帳號"
          value={account}
          handleChange={e => {
            const val = e.target.value;
            setFormVerifyIdentity(state => ({ ...state, account: val }));
          }}
        />
        <CustomInput
          name="phone"
          label="手機號碼"
          placeholder="10位數字"
          value={phone}
          handleChange={e => {
            const val = e.target.value;
            setFormVerifyIdentity(state => ({
              ...state,
              phone: val,
            }));
          }}
        />
        <Ant.Button
          className="submitButton verify-identity-button"
          type="primary"
          loading={loading.verifyIdentity}
          onClick={handleVerifyIdentity}
        >
          <p>驗證身份</p>
        </Ant.Button>
        {renderFormFooter()}
      </>
    );
  };

  const renderFormSavePassword = () => {
    const { newPassword, passwordConfirm } = formSavePassword;
    const errMsg = errorMessage.savePassword;
    return (
      <>
        {renderFormHeader()}
        <Message isError={!!errMsg}>
          {!!errMsg ? errMsg : message.savePassword}
        </Message>
        <CustomPasswordInput
          name="password"
          label="設定密碼"
          value={newPassword}
          handleChange={e => {
            const value = e.target.value;
            setFormSavePassword(state => ({
              ...state,
              newPassword: value,
            }));
          }}
        />
        <CustomPasswordInput
          name="password-confirm"
          label="再次輸入密碼"
          value={passwordConfirm}
          handleChange={e => {
            const value = e.target.value;
            setFormSavePassword(state => ({
              ...state,
              passwordConfirm: value,
            }));
          }}
        />
        {newPassword.length > 0 && (
          <PasswordRulesBox newPassword={newPassword}>
            {passwordRules.map((item, idx) => {
              return (
                <div key={idx} className="list-item-wrapper">
                  <Ant.Checkbox checked={item.rule.test(newPassword)} />
                  <span>{item.label}</span>
                </div>
              );
            })}
          </PasswordRulesBox>
        )}
        <Ant.Button
          className="submitButton save-password-button"
          type="primary"
          loading={loading.savePassword}
          onClick={handleSavePassword}
        >
          <p>儲存密碼</p>
        </Ant.Button>
        {renderFormFooter()}
      </>
    );
  };

  const renderForm = () => {
    switch (page) {
      case "getOTPVerify":
        return renderFormGetOTPVerify();
      case "verifyOTP":
        return renderFormVerifyOTP();
      case "verifyIdentity":
        return renderFormVerifyIdentity();
      case "savePassword":
        return renderFormSavePassword();
      default:
        return renderFormGetOTPVerify();
    }
  };

  // 當 countdown 為 0 時要停止 setInterval
  useEffect(() => {
    if (countDownResend === 0) {
      stopCountDownResend();
    }
  }, [countDownResend]);

  useEffect(() => {
    if (page === "getOTPVerify") {
      setFormGetOTP({ ...formGetOTPDefault });
      return;
    }
    if (page === "savePassword") {
      return;
    }
    if (page === "verifyOTP") {
      setErrorMessage(state => ({ ...state, verifyOTP: "" }));
      setFormVerifyOTP({ ...formVerifyOTPDefault });
      return;
    }
  }, [page]);

  return (
    <Center>
      <Wrapper>
        <Banner>
          <img className="inner" />
        </Banner>
        <LoginFormWrapper
          page={page}
          newPassword={formSavePassword.newPassword}
        >
          {renderForm()}
        </LoginFormWrapper>
      </Wrapper>
      <Widget.CopyrightFooter />
    </Center>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`;

const LoginFormWrapper = styled.div`
  width: 360px;
  height: 519px;
  margin-left: 24px;
  padding: 40px 40px 0 40px;
  box-shadow: 0 3px 6px 0px ${Color.GreyBlack_12};
  background-color: ${Color.GreyWhite};
  display: flex;
  flex-direction: column;

  .verifyButton,
  .submitButton {
    width: 280px;
    height: 40px;
    border: unset;
    border-radius: 20px;
    background-color: ${Color.CosurveyBlue};
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      background-color: ${Color.CosurveyGray};
    }
    &:active {
      background-color: ${Color.CosurveyGray_01};
    }
    &[disabled] {
      background-color: ${Color.CosurveyGray_01};
      p {
        color: ${Color.GreyBlack_10};
      }
    }

    .ant-spin {
      width: 24px;
      height: 24px;
      color: rgb(35, 128, 251);
    }

    p {
      margin-bottom: unset;
      font-family: PingFangTC;
      font-size: ${FontSize.head}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: ${Color.GreyWhite};
    }
  }

  .verifyButton {
    margin-bottom: 8px;
  }

  .account-wrapper,
  .password-confirm-wrapper,
  .phone-wrapper,
  .verifyCode-wrapper,
  .password-wrapper {
    > label {
      font-size: 14px;
      line-height: 1.57;
      color: ${Color.Black_85};
      display: inline-block;
      margin-bottom: 8px;
    }
  }

  .account-wrapper {
    margin-bottom: 32px;
  }

  .verifyCode-wrapper {
    margin-bottom: auto;
  }

  .phone-wrapper {
    margin-bottom: auto;
  }

  .password-confirm-wrapper {
    margin-bottom: ${props => (props.newPassword.length > 0 ? "13px" : "auto")};
  }

  .password-wrapper {
    margin-bottom: ${props => (props.page === "getOTPVerify" ? "5px" : "32px")};
  }

  .resend-otp-button {
    margin-bottom: 8px;
  }

  .verify-identity-button {
    margin: 0 auto 8px;
  }

  .save-password-button {
    margin-bottom: 8px;
  }
`;

const Banner = styled.div`
  width: 744px;
  height: 519px;
  background-image: url("../images/Website_Login_v2@3x.png");
  background-size: contain;

  & > .inner {
    width: 100%;
    /* padding-top: 100%; */
  }
`;

const Logo = styled.div`
  width: 128px;
  height: 32px;
  margin: 0 auto 0;
  margin-bottom: 31px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Message = styled.p`
  display: ${props => (!!props.children ? "block" : "none")};
  margin-bottom: 9px;
  font-family: "Noto Sans TC", "Helvetica Neue", "Consolas";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: justify;
  color: ${props => (props.isError ? Color.Red_5 : Color.MPurple_6)};
`;

const PasswordRulesBox = styled.div`
  width: 100%;
  height: 116px;
  margin-bottom: 12px;
  padding: 8px 0px 8px 16px;
  border-radius: 5px;
  border: ${Container.Info_Border};
  background-color: ${Container.Info_BGColor};
  animation: 0.3s linear ${fadeIn};

  > .list-item-wrapper:not(:last-child) {
    margin-bottom: 4px;
  }

  > .list-item-wrapper {
    * {
      cursor: default;
    }

    .ant-checkbox-inner {
      border-color: #d9d9d9 !important;
    }

    > span {
      margin-left: 8px;
    }
  }
`;

const AlreadyHaveAcWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;

  > span {
    margin-right: 8px;
  }

  > button {
    width: 80px;
    height: 24px;
    border-color: transparent;
    color: ${Color.MPurple_6};
    padding: 0;

    &:hover {
      border-color: transparent;
    }
  }
`;

const ForgotPasswordWrapper = styled.div`
  text-align: right;
  margin-bottom: auto;

  > button {
    width: 80px;
    height: 24px;
    border-color: transparent;
    color: ${Color.MPurple_6};
    line-height: 1.57;
    padding: 1px 12px;

    &:hover {
      border-color: transparent;
    }
  }
`;

export default connect(null, ActionCreator)(LoginForm);
