import React, { useState } from "react";
import * as Ant from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const CustomInput = props => {
  const { name, label, value, handleChange, ...otherProps } = props;
  return (
    <div className={`${name}-wrapper`}>
      <label htmlFor={name}>{label}</label>
      <Ant.Input
        id={name}
        autoComplete="off"
        value={value}
        onChange={handleChange}
        {...otherProps}
      />
    </div>
  );
};

const CustomPasswordInput = props => {
  const { name, label, value, handleChange, ...otherProps } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`${name}-wrapper`} style={{ position: "relative" }}>
      <label htmlFor={name}>{label}</label>
      <Ant.Input
        id={name}
        type={showPassword ? "text" : "password"}
        autoComplete="off"
        value={value}
        onChange={handleChange}
        {...otherProps}
      />
      <div
        onMouseDown={() => setShowPassword(true)}
        onMouseUp={() => setShowPassword(false)}
        style={{
          width: 32,
          height: 32,
          position: "absolute",
          top: 30,
          right: 0,
          zIndex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showPassword ? (
          <EyeOutlined size={20} />
        ) : (
          <EyeInvisibleOutlined size={20} />
        )}
      </div>
    </div>
  );
};

export { CustomInput, CustomPasswordInput };
